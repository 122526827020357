import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BlankComponent } from "./layouts/blank/blank.component";
import { FullComponent } from "./layouts/full/full.component";
import { canActivateTeam } from "./guards/auth.guard";
import { UserRoleGuard } from "./guards/user-role.guard";
import { environment } from "src/environments/environment";

const routes: Routes = [
  { path: "", redirectTo: "/auth/login", pathMatch: "full" },
  {
    path: "",
    component: BlankComponent,
    children: [
      { path: "", redirectTo: "/auth/login", pathMatch: "full" },
      {
        path: "auth",
        loadChildren: () =>
          import("../app/modules/authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: "",
    component: FullComponent,
    canActivate: [canActivateTeam],
    children: [
      {
        path: "doc-ai",
        loadChildren: () => {
          switch (environment.Theme) {
            case "kidm":
              return import("./modules/kidm/kidm.module").then(
                (m) => m.KidmModule
              );
            case "ra":
              return import("./modules/ra/ra.module").then((m) => m.RaModule);

            default:
              return import("./modules/doc/doc.module").then(
                (m) => m.DocModule
              );
          }
        },
      },

      {
        path: "admin",
        canActivate: [UserRoleGuard],
        loadChildren: () =>
          import("./modules/admin/admin.module").then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "auth/login",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  cSessionService_GetDocumentManagerNestedFolder() {
    throw new Error('Method not implemented.');
  }

  constructor() { }
  cSessionService_set(key, oData) {
    localStorage.setItem(key, JSON.stringify(oData))
  }
  cSessionService_get(key) {
    let str = localStorage.getItem(key);
    if (str) {
      return JSON.parse(str);
    }
    return {};
  }
  // set access token
  SessionService_SetAccessToken(oData: any) {
    localStorage.setItem(environment.AccessToken, JSON.stringify(oData))
  }
  // get access token
  SessionService_GetAccessToken() {
    return JSON.parse(localStorage.getItem(environment.AccessToken))
  }

  // set user details
  SessionService_SetUserDetails(oData: any) {
    localStorage.setItem('userDetails', btoa(JSON.stringify(oData)));
  }

  // get user details
  SessionService_GetUserDetails() {
    const encodedData = localStorage.getItem('userDetails');
    if (encodedData) return JSON.parse(atob(encodedData));
  }

  //***********Storing and getting Current Page  */
  SessionService_SetCurrentPage(oData: any) {
    localStorage.setItem('currentPage', JSON.stringify(oData))
  }
  SessionService_GetCurrentPage() {
    return JSON.parse(localStorage.getItem('currentPage'));
  }

  //***********Storing and getting Current Page  */
  SessionService_SetPageSize(oData: any) {
    localStorage.setItem('pageSize', JSON.stringify(oData))
  }
  SessionService_GetPageSize() {
    return JSON.parse(localStorage.getItem('pageSize'));
  }
  SessionService_SetApplicationSideBarConfigurationFile(oData: any) {
    this.cSessionService_set('lsConfigFile', oData);
  }
  SessionService_GetApplicationSideBarConfigurationFile() {
    return this.cSessionService_get('lsConfigFile');
  }
  cSessionService_SetDocumentManagerSelectedItem(oData) {
    this.cSessionService_set('DocumentManagerSelectedItem', oData);
  }
  cSessionService_GetDocumentManagerSelectedItem() {
    return this.cSessionService_get('DocumentManagerSelectedItem');
  }
  SessionService_SetSate(oData: any) {
    this.cSessionService_set('lsState', oData);
  }
  SessionService_GetState() {
    return this.cSessionService_get('lsState');
  }
  cSessionService_SetDocManagerFolderSearchString(oData) {
    this.cSessionService_set('DocManagerFolderSearchString', oData);
  }
  cSessionService_GetDocManagerFolderSearchString() {
    return this.cSessionService_get('DocManagerFolderSearchString');
  }
  
  cSessionService_SetDocumentManagerParentFolderItem(oData) {
    this.cSessionService_set('DocumentManagerParentFolder', oData);
  }
  cSessionService_GetDocumentManagerParentFolderItem() {
    return this.cSessionService_get('DocumentManagerParentFolder');
  }
  cSessionService_SetDocumentManagerNestedFolder(oData) {
    this.cSessionService_set('DocumentManagerNestedFolder', oData);
  }
  cSessionService_GetDocumentManagerNestedFolderItem() {
    return this.cSessionService_get('DocumentManagerNestedFolder');
  }
  cSessionService_SetDocumentManagerAdditionalFolder(oData) {
    this.cSessionService_set('DocumentManagerAdditionalFolder', oData);
  }
  cSessionService_GetDocumentManagerAdditionalFolderItem() {
    return this.cSessionService_get('DocumentManagerAdditionalFolder');
  }
}


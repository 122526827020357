import { Component, Inject, Renderer2 } from "@angular/core";
import { environment } from "src/environments/environment";
import { SessionService } from "./shared/session.service";
import { DataService } from "./shared/data.service";
import { DOCUMENT } from "@angular/common";
import { UiService } from "./shared/ui.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Trillo DocAI.";
  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,private uiService: UiService) 
  {
    this.renderer.addClass(document.body, environment.Theme);
    this.uiService.updateFaviconBasedOnTheme();
    this.uiService.updateTitleBasedOnTheme();
  }
}

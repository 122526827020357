import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { navbarData } from './nav-data';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  MENUITEMS: any = navbarData;
  items = new BehaviorSubject<any>(this.MENUITEMS);
  constructor() { }
}

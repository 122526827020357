import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  constructor(private titleService: Title) {}

  updateTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  updateFavicon(newFaviconPath: string): void {
    const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    if (favicon) {
      favicon.href = newFaviconPath;
    }
  }
  getLogo(){
      let logoPath: string;
      switch (environment.Theme) {
        case 'kidm':
          logoPath = '../../../../assets/images/kidm-logo.png';
          break;
        case 'ra':
        logoPath = '../../../../assets/images/main-logo-ra.png';
        break;
        default:
          logoPath = './../../../assets/images/logo-text-dark.png';
          break;
      }
      return logoPath;
    }
  updateFaviconBasedOnTheme(): void {
    const favcon = document.getElementById("favicon") as HTMLLinkElement;
    if (favcon) {
      favcon.href = this.getFavicon();
    }
  }
  getFavicon(): string {
    let faviconPath: string;
    switch (environment.Theme) {
      case 'kidm':
        faviconPath = "../assets/images/favicon/kidm.png";
        break;
      case 'ra':
        faviconPath = "../assets/images/favicon/logo-ra.png";
        break;
      default:
        faviconPath = "../assets/images/favicon/trillo.png";
        break;
    }
    return faviconPath;
  }
  updateTitleBasedOnTheme(): void {
    let titleElement = document.getElementById("app-title");
    if (titleElement) {
      titleElement.textContent = environment.Title;  // Update the app title
    }

    const currentURL = document.location.origin;
    let title: string;

    if (currentURL) {
      switch (environment.Theme) {
        case "kidm":
          title = "ICM";
          break;
        case "ra":
          title = "RA Q&A";
          break;
        default:
          title = "Trillo DocAI";
          break;
      }

      // Update the browser tab title using the service
      this.updateTitle(title);
    }
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SidebarService } from "./sidebar.service";
import { environment } from "src/environments/environment";
import { DataService } from "../data.service";
import { SessionService } from "../session.service";
import { Router } from "@angular/router";

interface RouteInfo {
  routerLink: string;
  icon: string;
  label: string;
}

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnChanges {
  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();
  collapsed: boolean = false;
  public navData: RouteInfo[] = [];
  SidebarList: any = [];
  lConfigurations: any = [];
  screenWidth = 0;
  @Input() isSideNavCollapsed;
  userDetails: any;
  environmentTheme = environment.Theme;

  constructor(
    private menuServise: SidebarService,
    private cSessionsService: SessionService,
    public router: Router
  ) {}
  ngOnInit(): void {
    this.userDetails = this.cSessionsService.SessionService_GetUserDetails();
    let lConfig =
      this.cSessionsService.SessionService_GetApplicationSideBarConfigurationFile();
    this.lConfigurations = lConfig?.menus;
    console.log(this.lConfigurations,this.userDetails);
    this.VerticalSidebarComponent_DisplayMenuItemBasedOnUserRole();

    
    // if (
    //   this.userDetails.role == "admin" ||
    //   this.userDetails.role !== "editor"
    // ) {
    //   this.collapsed = true;
    //   console.log("inside if ::: COLLAPSED NGONINIT ===>", this.collapsed);
    // }
  }
  ngOnChanges(changes: SimpleChanges): void {
      this.collapsed = this.isSideNavCollapsed;
  }
  VerticalSidebarComponent_DisplayMenuItemBasedOnUserRole() {
    var lMenuItems = [];
    this.navData = [];
    this.menuServise.items.subscribe(async (menuItems) => {
      lMenuItems = menuItems; 
      console.log(lMenuItems,"menu items");
           
    });
    for (
      let nConfigurationItemIndex = 0;
      nConfigurationItemIndex < this.lConfigurations?.length;
      nConfigurationItemIndex++
    ) {
      let element = this.lConfigurations[nConfigurationItemIndex];
      for (
        let nMenuItemToPushIndex = 0;
        nMenuItemToPushIndex < lMenuItems.length;
        nMenuItemToPushIndex++
      ) {
        let item = lMenuItems[nMenuItemToPushIndex];
        console.log("in the item",element.routerLink,item.routerLink);
        if (element.routerLink === item.routerLink) {
          
          if (
            item.routerLink === "/doc-manager" &&
            this.environmentTheme == "kidm"
          ) {
            item.label = "Doc AI";
          }
          this.navData.push(item);
          break;
        }
      }
    }
    console.log(this.navData);
    
  }
  SidebarComponent_CustomizingtheSideBar(oIncomingObject) {
    let dataBaseObject: any = { database: false, bucket: false };
    dataBaseObject.database = oIncomingObject.database
      ? oIncomingObject.database
      : false;
    dataBaseObject.bucket = oIncomingObject.bucket
      ? oIncomingObject.bucket
      : false;
    if (dataBaseObject?.bucket == true && dataBaseObject?.database == false) {
      this.SidebarList = [];
      this.navData.forEach((element) => {
        if (element.label == "File Manager" || element.label == "Home")
          this.SidebarList.push(element);
      });
    } else if (
      dataBaseObject?.database == true &&
      dataBaseObject?.bucket == false
    ) {
      this.SidebarList = [];
      this.navData.forEach((element) => {
        if (element.label != "File Manager") this.SidebarList.push(element);
      });
    } else if (
      dataBaseObject?.bucket == true &&
      dataBaseObject?.database == true
    ) {
      this.SidebarList = [];
      this.navData.forEach((element) => {
        this.SidebarList.push(element);
      });
    }
    if (dataBaseObject?.bucket == true && dataBaseObject?.database == false) {
      this.SidebarList = [];
      this.navData.forEach((element) => {
        if (element.label == "File Manager" || element.label == "Home")
          this.SidebarList.push(element);
      });
    }
  }
  SidebarComponent_CloseSidenav() {
    this.collapsed = false;
    this.onToggleSidenav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }
  SidebarComponent_CloseSidebar(incomingRouter) {   
      this.SidebarComponent_CloseSidenav();
    // if (incomingRouter != "/doc-manager") {
    //   this.oDataService.globalSearch.next({
    //     folderId: "",
    //     searchBoolan: false,
    //   });
    //   this.oDataService.resetSearch.next(true);
    // }
  }
}

export const navbarData = [
    {
        routerLink: '/doc-ai',
        icon:'fa-home',
        label: 'Home',
    },
    {
        routerLink: '/doc-ai/search-qna',
        icon:'fa-print-magnifying-glass',
        label: 'Search & QnA',
    },
    {
        routerLink: '/doc-ai/data-review',
        icon:'fa-solid fa-book',
        label: 'Data Review'
    },
    {
        routerLink: '/doc-ai/matching',
        icon:'fa-file-pen',
        label: 'Matching'
    },
]
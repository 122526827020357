import { Injectable } from '@angular/core';
import { HttpRequest,HttpHandler,HttpEvent,HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { Observable,  throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/shared/session.service';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private oSessionService:SessionService){}
  oRequestWithHeaders  : any;
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> { 
    let oHeaders = 
    {
      'Accept':'*/*',
      'x-app-name':'main',
      'x-org-name': environment.OrgName,
      'content-type':'application/json',
      'Authorization':'Bearer ' + this.oSessionService.SessionService_GetAccessToken(),
    }
    
    if (request.url.includes('/uploadUsers')) delete oHeaders['content-type'];
    if(
       (request.url.includes('/forgotPassword')) || 
       (request.url.includes('/_preauthsvc/user/resetPassword'))
       ) delete oHeaders['Authorization'];
         this.oRequestWithHeaders  = request.clone({ setHeaders: oHeaders });
        return next.handle(this.oRequestWithHeaders)
    .pipe(retry(0), catchError((error: HttpErrorResponse)=> {
      let errorMessage: any;
      if(error.error instanceof ErrorEvent){
        //client side error
        errorMessage = `Error: ${error.error.message}`;
      }
      else
      {
        //server side error
        // errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
        errorMessage = error.error.message
      }
      return throwError(() => new Error(errorMessage))
    }))
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/data.service';
import { SessionService } from 'src/app/shared/session.service';
interface SideNavToggle{
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent  {
  // closeSidebar: string = "";
  userDetails: any;
  sidebaropen: any;
  isSideNavCollapsed = false;
  check:boolean=false
  screenWidth: number = 0
  constructor(private dataService : DataService,private oRouter : Router,private sessionService : SessionService){}
  ngOnInit(): void {
    // this.dataService.closeSidebar.subscribe(res =>{
    //   this.closeSidebar = res;
    // })
    // this.closeSidebar = this.oRouter.url;
    this.userDetails = this.sessionService.SessionService_GetUserDetails()
    
  }
  onToggleSidenav(data: SideNavToggle):void 
  {    
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
    this.check=data.collapsed
  }
  dismissOverlay()
  {
    this.screenWidth = 0;
    this.isSideNavCollapsed = false;
    this.check=false
  }
}


import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private http: HttpClient) { }
  getUserDetails = new BehaviorSubject<any>("");
  closeSidebar = new BehaviorSubject<any>("");
  selectedFiles = new BehaviorSubject<any>("");
  fileDetail = new BehaviorSubject<any>({ query: {} });
  setTargetFolder = new BehaviorSubject<any>("");
  sCurrentDocFolderName = new BehaviorSubject<string>("");
  bFetchDeletedFilesCheck = new BehaviorSubject<boolean>(false);
  refreshDoc = new BehaviorSubject<boolean>(false);
  responseMessage = new BehaviorSubject<any>("");
  showresponse = new BehaviorSubject<any>({ arg1: "", arg2: false });
  bRenameLoader = new BehaviorSubject<boolean>(false);
  renameDocFolder = new BehaviorSubject<boolean>(false);
  docType = new BehaviorSubject<any>({});
  refresh = new BehaviorSubject<boolean>(false);
  addingNewDocSubFolder = new BehaviorSubject<string>("");
  switchToDocuments = new BehaviorSubject<boolean>(false);

  globalSearch = new BehaviorSubject<any>({
    folderId: "",
    searchBoolean: false,
  });
  DataService_GetGlobalSearchResultsForDocumentManager(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + "/docsvc/search2", body);
  }
  DataService_GetSearchHistory(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/api/v1.1/docsvc/folder/multiTurnConversations/" + body
    );
  }
  DataService_GetConversations(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/api/v1.1/docsvc/multiTurnConversation/" + body
    );
  }
  DataService_ExtractDataFromGlobalSearchFiles(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/docsvc/docAIResult?docId=" + body
    );
  }

  forgotPassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_preauthsvc/user/forgotPassword",
      body
    );
  }

  GetUsersList(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/page/auth/vault",
      body
    );
  }
  CreateUser(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/newUser",
      body
    );
  }

  UploadUsers(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/authds/uploadUsers",
      body
    );
  }

  EditUser(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/editUser",
      body
    );
  }

  ResetPassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/resetPassword",
      body
    );
  }

  SuspendUser(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/toggleSuspendActive",
      body
    );
  }

  DeleteUser(id: any): Observable<any> {
    return this.http.delete<any>(
      environment.BaseURL + "/_service/um/deleteUser" + "?id=" + id
    );
  }

  AddNewTenant(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/save/auth/vault/Org",
      body
    );
  }

  GetUserProfile(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetUserProfile",
      body
    );
  }

  SetUserProfile(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/editMyProfile",
      body
    );
  }

  ChangePassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/changeMyPassword",
      body
    );
  }

  DataService_DocRetrieveSignedUrl(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/cloudstorage/folder/retrieveSignedUrl",
      body
    );
  }

  cDataService_saveThumbState(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/api/v1.1/docsvc/saveThumbState", body
    );
  }

  cDataService_deleteSearchHistory(body): Observable<any> {
    return this.http.delete<any>(
      environment.BaseURL + "/api/v1.1/docsvc/multiTurnConversation/" + body
    );
  }
  cDataService_GetDocFoldersAndDocs(body: any): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/foldersAndPaginatedDocs",
      body
    );
  }
  cDataService_GetDocFolders(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + "/docsvc/folders");
  }

  DataService_RestoreFromTrash(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/foldersvc/cloudstorage/file/restoreMany",
      body
    );
  }

  DataService_DeleteDocs(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/cloudstorage/doc/deleteMany",
      body
    );
  }
  DataService_PasteDocandFolders(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/cloudstorage/doc/move",
      body
    );
  }
  DataService_CreateReferenceFile(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/CreateReferenceFile",
      body
    );
  }
  DataService_UpdateFileStatus(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/foldersvc/cloudstorage/file/updateStatus",
      body
    );
  }
  DataService_CopyDocs(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/cloudstorage/doc/copy",
      body
    );
  }
  DataService_DeleteDocFolders(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/cloudstorage/folder/delete",
      body
    );
  }
  DataService_GetFileDetails(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetFileDetails",
      body
    );
  }
  DataService_RenameDoc(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/cloudstorage/doc/rename",
      body
    );
  }
  DataService_isBusy(): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/foldersvc/cloudstorage/folder/isBusy"
    );
  }
  DataService_RenameDocFolder(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/cloudstorage/folder/rename",
      body
    );
  }

  DataService_ShareFolderFile(body, IncomingType): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL +
        "/foldersvc/cloudstorage/" +
        IncomingType +
        "/share",
      body
    );
  }
  DataService_UpdateDocFolderProperties(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/gcs/folder/updateDocFolderProperties",
      body
    );
  }
  DataService_UpdateDocProperties(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/gcs/doc/updateDocProperties",
      body
    );
  }
  DataService_NewDocFolder(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/cloudstorage/folder/create",
      body
    );
  }
  DataService_ExecuteWorkflowForSelectedFolder(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/executeWorkflow",
      body
    );
  }
  DataService_CreateDocDownloadSignedUrl(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL +
        "/docsvc/cloudstorage/folder/createDownloadSignedUrl",
      body
    );
  }
  DataService_GetAccessRuleByResource(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL +
        "/accesscontrol/getAccessControlRuleByResource/" +
        body
    );
  }
  DataService_SearchUsers(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL +
        "/lookup/query/auth.vault.User/userId?partialPhrase=" +
        body
    );
  }
  DataService_SearchGroups(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL +
        "/lookup/query/shared.common.Group/name?partialPhrase=" +
        body
    );
  }
  DataService_GetRolesList(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL + "/_service/um/getRoles");
  }
  DataService_SaveAccessControlRule(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/accesscontrol/saveAccessControlRule",
      body
    );
  }
  cDataService_GetSchema(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/docsvc/docSchemaForTarget/" + body
    );
  }
  DataService_SaveDocSchema(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/saveDocSchema",
      body
    );
  }
  DataService_ResetProcessingStatus(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/api/v1.1/docsvc/resetStatus",
      body
    );
  }
  cDataService_saveProperties(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/api/v1.1/docsvc/doc/properties/save",
      body
    );
  }
  cDataService_getProperties(params: { [key: string]: any }): Observable<any> {
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get<any>(
      `${environment.BaseURL}/api/v1.1//docsvc/doc/properties`,
      { params: httpParams }
    );
  }
  cDataService_ExecuteMatch(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + "/docsvc/match", body);
  }
  cDataService_downloadEntities(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/api/v1.1/docsvc/folder/download/entities/" + body
    );
  }
  cDataService_downloadFields(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/api/v1.1/docsvc/folder/download/fields/" + body
    );
  }

}

<app-header
  [isSideNavCollapsed]="isSideNavCollapsed"
  (onToggleSidenav)="onToggleSidenav($event)"
></app-header>
<app-sidebar
  [isSideNavCollapsed]="isSideNavCollapsed"
  (onToggleSidenav)="onToggleSidenav($event)"
></app-sidebar>

<div
  class="body"
>
  <div class="position-relative">
    <router-outlet></router-outlet>
  </div>
</div>

<div
  *ngIf="
    isSideNavCollapsed &&
    check"
  class="admin-overlay"
  (click)="dismissOverlay()"
></div>
